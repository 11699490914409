body {
  background-repeat: no-repeat;
  background-color: #000;
  background-position: center center;
  background-size: cover;
}
.App {
  text-align: center;
}

.App-logo {
  height: 20vmin;
  pointer-events: none;
  min-height: 235px;
}

.App-header {

  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  
  font-size: calc(10px + 2vmin);
  color: white;
}
.top-texture {
  background: url('./images/texture.png');
  background-size: cover;
  width: 100%;
  height: 99px;
  position: absolute;
  transform: rotate(180deg);
}
.bottom-texture {
  background: url('./images/texture.png');
  background-size: cover;
  width: 100%;
  height: 125px;
  position: absolute;
  top: 41vh;
}
.header {
  background: url('./images/boat.jpg');
  background-size: cover;
  width: 100%;
  height: 54vh;
  background-position: center;
  background-repeat: no-repeat;
  align-content: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
footer {
  margin-top: 60px;
  padding-bottom: 40px;
}
footer img {
  max-width: 250px;
}
.App-link {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 0;
  padding: 0 3rem;
  cursor: pointer;
  font: inherit;
  font-size: 1.5rem;
  text-decoration: none;
  color: rgb(255, 255, 255);
  transition: box-shadow 0.1 ease;
  -webkit-appearance: none;
  appearance: none;
  background-color: rgb(175, 13, 22);
  min-width: 122px;
  min-height: 47px;
  border-radius: 41px;
}
p {
  font-size: 18px;
}
section {
  margin: 40px 0 0;
  padding: 0 20px;
}
img.boat {
  width: 100%;
  margin: 40px 0 0;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
